import { Action } from '../type/Common'
import { useData } from './store'

const BLOG_SCREEN_DATA_CHANGE = 'BLOG_SCREEN_DATA_CHANGE'
export interface Blog {
  name: string
}
export interface BlogPage {
  listBlogScreen: Array<Blog>
}

export const loadBlogsList = (): { type: string; payload: { listBlogScreen: Array<Blog> } } => ({
  type: BLOG_SCREEN_DATA_CHANGE,
  payload: { listBlogScreen: [{ name: 'test blog 1' }, { name: 'test blog 2' }] }
})

export const BlogReducer = (state: BlogPage = { listBlogScreen: [] }, action: Action): BlogPage => {
  switch (action.type) {
    case BLOG_SCREEN_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
export const useBlogData = (): { blogPage?: BlogPage; loadBlogList: () => void } => {
  const { state, dispatch } = useData()
  return {
    blogPage: state?.blogPage,
    loadBlogList: () =>
      dispatch({ type: BLOG_SCREEN_DATA_CHANGE, payload: { listBlogScreen: [{ name: 'test blog 1' }, { name: 'test blog 2' }] } })
  }
}
