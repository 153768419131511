import css from 'styled-jsx/css'

export const SidebarStyle = css.global`
  .sidebar {
    display: none;
  }

  @media screen and (max-width: 1023.98px) {
    .sidebar {
      position: fixed;
      z-index: 999;
      width: 100%;
      background: #fff;
      min-height: var(--layout-header-height);
      display: flex;
      align-items: center;
    }

    .sidebar .none-shadow {
      box-shadow: none;
    }

    .sidebar .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 16px;
    }

    .sidebar .logo .header-logo-img {
      height: 34px;
    }

    .sidebar .logo .btn-link {
      margin-right: 74px;
      background-color: #1890ff;
      padding: 9px 24px;
      border-radius: 4px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
    }
    .sidebar .logo .mr-0 {
      margin-right: 0;
    }

    .sidebar #nav-icon {
      position: absolute;
      right: 5%;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      z-index: 555;
      background-color: transparent;
      border-color: transparent;
      border-radius: 50%;
      width: 34px;
      padding: 7px 0px 5px 4px;
      background-color: #fff;
    }

    .sidebar #nav-icon:focus {
      outline: none;
    }

    .sidebar #nav-icon .wrap-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 16px;
    }

    .sidebar #nav-icon .wrap-icon span {
      width: 22px;
      height: 2px;
      display: block;
      background: #262626;
      transition: all 0.4s ease-in-out;
    }

    .sidebar #nav-icon.open span:nth-child(1) {
      transform: translate(0, 6px) rotate(45deg);
      background-color: #262626;
    }

    .sidebar #nav-icon.open span:nth-child(2) {
      opacity: 0;
    }

    .sidebar #nav-icon.open span:nth-child(3) {
      transform: translate(0, -8px) rotate(-45deg);
      background-color: #262626;
    }
  }

  body .btn-close-sidebar {
    border: none;
    box-shadow: unset;
    font-size: 20px;
    padding: 0;
    height: unset;
  }

  body .sidebar-drawer .ant-drawer-open {
    z-index: 1000;
  }

  body .sidebar-drawer .ant-drawer-body,
  .sidebar-account-menu .ant-drawer-body {
    padding: 0 !important;
  }

  .sidebar-drawer .sidebar-account {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 8px;
  }

  .sidebar-account__avatar {
    margin-right: 12px;
  }

  .sidebar-account__name {
    color: #141921;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .sidebar-account__name.limit-width {
    width: 77px;
  }
  .sidebar-account__verified {
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    color: #1890ff;
    text-transform: uppercase;
    background-color: #e6f7ff;
    border-radius: 4px;
    margin-left: 8px;
    padding: 2px 4px;
    display: inline-block;
  }
  .sidebar-account__role {
    color: #141921;
  }
  .sidebar-account__middle-col {
    width: 157px;
  }
  .sidebar-account__email {
    font-size: 12px;
    line-height: 20px;
    color: #7a7c82;
    text-overflow: ellipsis;

    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
  }
  .sidebar-company {
    padding: 10px 16px;
  }
  .sidebar-company__career-site {
    font-size: 12px;
    line-height: 20px;
    color: #4f535b;
  }
  .sidebar-company__name {
    color: #000000;
    font-weight: bold;
  }

  .ant-drawer-body .btn-link-group {
    font-size: 12px;
    line-height: 18px;
    color: #7a7c82;
    font-weight: 500;
    padding: 16px 16px 7px;
    text-transform: uppercase;
  }
  body .ant-drawer-body .btn-link {
    background-color: #fff;
    padding: 13px 16px;
    border: none;
    font-weight: 500;
    color: #141921;
    display: flex;
    width: 100%;
    text-align: left;
  }
  body .ant-drawer-body .btn-link a {
    font-weight: 500;
    color: #141921;
  }
  .sidebar-account__divider {
    height: 1px;
    background-color: #e8e8e8;
    margin: 8px 0;
  }
  .sidebar-account-menu__title {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: bold;
    margin-left: 16px;
  }
  .sidebar-account-menu .ant-drawer-header {
    margin-bottom: 8px;
  }

  body .ant-drawer-body .group-link p {
    margin-bottom: 24px;
  }
  body .ant-drawer-body .view-company-name {
    display: flex;
    flex-direction: column;
  }

  body .ant-drawer-body .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  body .ant-drawer-body .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  body .ant-drawer-body .view-company-name .label-company-name:hover {
    color: #1890ff;
  }

  body .sidebar-account-menu .btn-log-out {
    text-align: left;
    border: none;
    box-shadow: unset;
    height: unset;
    display: flex;
  }
  body .ant-drawer-body .group-button .btn-log-out .btn-log-out-text {
    margin-left: 0;
  }
  .sidebar-before-login .ant-drawer-body {
    padding: 16px 0;
  }
  .sidebar-before-login .group-button-employer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  .sidebar-before-login .group-button-employer .btn {
    width: calc(50% - 8px);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
  body .ant-drawer-body .group-button-before {
  }

  body .ant-drawer-body .group-button .btn-employer {
    padding: 0;
    border: none;
    text-align: left;
    box-shadow: none;
    margin-top: 30px;
  }
  body .ant-drawer-body .group-button .btn-employer .title {
    font-size: 20px;
    color: #1890ff;
    font-weight: 500;
  }
  body .ant-drawer-body .group-button .btn-employer .sub-title {
    font-size: 14px;
    color: #8c8c8c;
  }
  body .ant-drawer-body .tag-beta {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #ff4d4f;
    margin-left: 8px !important;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }
  body .ant-drawer-body .ant-divider {
    background-color: #e8e8e8 !important;
    margin: 3px 0 4px;
  }
  body .ant-drawer-body .download {
    margin-top: 53px;
  }

  body .ant-drawer-body .download .logo-download {
    height: 36px;
  }

  body .ant-drawer-body .download .logo-download img {
    width: 100%;
  }

  body .ant-drawer-body .icon {
    margin-right: 12px;
  }

  body .sidebar-collapse {
    margin-bottom: 24px;
  }

  body .sidebar-collapse .ant-collapse-item {
    border: none;
  }

  body .sidebar-collapse .ant-collapse-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #595959 !important;
    padding-left: 0 !important;
    background-color: #fff !important;
    padding-bottom: 24px;
  }

  body .sidebar-collapse .sub-menu {
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    font-weight: normal;
    text-transform: lowercase;
  }
  body .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }

  body .ant-drawer-footer {
    padding: 0;
    border: none;
  }

  body .sidebar-lang {
    display: flex;
    align-items: center;
  }

  body .sidebar-lang .btn-lang {
    background-color: #fff;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    border-right: 1px solid #d1d7dc;
    opacity: 0.4;
  }
  body .sidebar-lang span {
    color: #141921;
    font-weight: 500;
  }
  body .sidebar-lang .btn-lang.activeLang {
    opacity: 1;
  }
  body .sidebar-lang .btn-lang:last-child {
    border-right: none;
    padding-left: 16px;
  }

  body .ant-drawer-header {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  body .btn-employer {
    background-color: #f9fafc;
    padding: 20px 16px;
    border: none;
    text-align: left;
    box-shadow: none;
    display: flex;
    width: 100%;
  }
  body .ant-drawer-footer .btn-employer .icon-left {
    margin-right: 12px;
  }

  body .ant-drawer-footer .btn-employer .title {
    /* font-size: 18px; */
    color: #1890ff;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  body .ant-drawer-footer .btn-employer .sub-title {
    font-size: 12px;
    line-height: 20px;
    color: #141921;
  }

  body .ant-drawer-footer .btn-employer .icon-right {
    margin-left: auto;
  }
  .sidebar .view-company-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 64px;
  }

  .sidebar .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  .sidebar .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  .sidebar .view-company-name .label-company-name:hover {
    color: #1890ff;
  }
`
