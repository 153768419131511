import { SkillType, Action, FastJsonList, NestedCategoryType, InviteTeamMemberType } from '../type/Common'
import { CandidateProfileDataType } from './profileData'
import { useData } from './store'
import { useEffect } from 'react'
import { API_URL_SKILLS } from '../utilities/APIConstant'
import { AxiosResponse } from 'axios'
import { apiPublicGetTeamMember } from '../@core/api/public.api'
import { MemberIndexType } from '../type/UserType'
import { useClient } from 'src/@legacy/@core/hooks/useClient'
import { useLocation } from 'src/@legacy/@core/hooks/UseLocation'
import { useJobCategories } from 'src/@legacy/@core/hooks/UseJobCategory'

const PUBLIC_DATA_STATE_CHANGE = 'PUBLIC_DATA_STATE_CHANGE'
type LocationType = {
  id: number
  state: string
  state_vn: string
}
export type PublicDataType = {
  skills?: SkillType[]
  locations?: FastJsonList<LocationType>
  job_categories?: NestedCategoryType[]
  listMember?: FastJsonList<InviteTeamMemberType>
  loadingCategory?: boolean
  loadingLocation?: boolean
}
export function getDefaultPublicData(): PublicDataType {
  return {}
}
export const usePublicData = (
  loadingField: {
    preventLoadSkill?: boolean
  } = {}
): {
  skills?: SkillType[]
  locations?: FastJsonList<LocationType>
  job_categories?: NestedCategoryType[]
  listMember?: FastJsonList<InviteTeamMemberType>
  fetchTeamMember: () => Promise<any>
} => {
  const { preventLoadSkill } = loadingField
  const { state, dispatch } = useData()
  const { client } = useClient()
  const publicData = state.publicData
  useEffect(() => {
    if (!preventLoadSkill && !publicData?.skills) {
      client.get(API_URL_SKILLS, {}).then((res: AxiosResponse<FastJsonList<SkillType>>) => {
        dispatch({ type: PUBLIC_DATA_STATE_CHANGE, payload: { skills: res.data.data.map((ser) => ser.attributes) } })
      })
    }
  }, [])

  const fetchTeamMember = (key?: string) =>
    apiPublicGetTeamMember(key).then((data: FastJsonList<MemberIndexType>) => {
      dispatch({
        type: PUBLIC_DATA_STATE_CHANGE,
        payload: {
          listMember: data
        }
      })
    })

  const locations = useLocation()
  const { job_categories } = useJobCategories()
  return {
    skills: state?.publicData?.skills,
    locations: locations,
    job_categories,
    listMember: state?.publicData?.listMember,
    fetchTeamMember
  }
}

export const PublicDataReducer = (state: PublicDataType = getDefaultPublicData(), action: Action): CandidateProfileDataType => {
  switch (action.type) {
    case PUBLIC_DATA_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
