import css from 'styled-jsx/css'

export const FontsStyle = css.global`
  .f-size-16 {
    font-size: 16px;
  }

  .f-size-20 {
    font-size: 20px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  body .f-weight {
    font-weight: 600;
  }
`
