import { useData } from '../store'
import { Action } from '../../type/Common'

const CLIENT_DATA_CHANGE = 'CLIENT_DATA_CHANGE'

export const ClientReducer = (state: any = { readyForNext: false, loadingJob: true, firstLoadSuccess: false }, action: Action): any => {
  switch (action.type) {
    case CLIENT_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

export const useClientData = (): {
  companyClient: any
} => {
  // todo: Refactoring code below for readability
  // separate concern on paging and job fetching
  const { state } = useData()
  const companyClient = state?.clientCompany

  return {
    companyClient
  }
}
