import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { Action } from '../type/Common'
import { useData } from './store'

export const HISTORY_DATA_CHANGE = 'HISTORY_DATA_CHANGE'
export type HistoryDataType = {
  listPath?: string[]
}
export const HistoryReducer = (state: HistoryDataType = {}, action: Action): HistoryDataType => {
  switch (action.type) {
    case HISTORY_DATA_CHANGE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
export function useHistoryData() {
  const { state, dispatch } = useData()
  const { history } = state
  const router = useAppRouter()
  const getLastPath = function () {
    if (history.listPath) {
      return
    }
    return history.listPath && history.listPath[history.listPath.length - 1]
  }
  return {
    pushPath: function (path: string) {
      return dispatch({ type: HISTORY_DATA_CHANGE, payload: { listPath: [...(history.listPath || []), path] } })
    },
    getLength: function () {
      return (history.listPath || []).length
    },
    pushBack: function (indexPath: string) {
      if ((history.listPath || []).length > 1) {
        const reverseHistory: string[] = [...history.listPath].reverse()
        const lastPathNotUplicated = reverseHistory.find((path) => path !== router.asPath)
        if (lastPathNotUplicated) {
          router.replace(lastPathNotUplicated)
        } else {
          router.replace(indexPath)
        }
      } else {
        router.push(indexPath)
      }
    },
    getLastPath
  }
}
