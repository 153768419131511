import React, { ReactElement } from 'react'
import {
  Action,
  FastJsonList,
  JobCategorySerializeType,
  NestedCategoryType,
  FreecLocationType,
  JobLevelType,
  SkillYearType,
  SkillType,
  LanguageType
} from '../type/Common'
import { useData } from './store'
import { useEffect } from 'react'
import { API_URL_JOB_CATEGORIES, API_URL_SKILLS, API_FETCH_LOCATION } from '../utilities/APIConstant'
import { AxiosResponse } from 'axios'

import {
  CalculatorOutlined,
  BulbOutlined,
  TeamOutlined,
  LineChartOutlined,
  SettingOutlined,
  UserOutlined,
  LaptopOutlined,
  SoundOutlined,
  EllipsisOutlined
} from '@ant-design/icons'
import { skill_years } from './utilities'
import { useClient } from 'src/@legacy/@core/hooks/useClient'

const ONBOARDING_STATE_CHANGE = 'ONBOARDING_STATE_CHANGE'

const jobLevel: JobLevelType[] = [
  { id: 0, name: 'Intern', name_vn: 'Thực tập' },
  { id: 1, name: 'Junior', name_vn: 'Mới tốt nghiệp' },
  { id: 2, name: 'Senior', name_vn: 'Chuyên viên' },
  { id: 3, name: 'Supervisor', name_vn: 'Trưởng nhóm/Giám sát' },
  { id: 4, name: 'Manager', name_vn: 'Trưởng phòng' },
  { id: 5, name: 'Director & above', name_vn: 'Giám đốc và vị trí cấp cao' }
]

type CategoryIdIconMap = { [id: string]: ReactElement }

const category_id_icom_map: CategoryIdIconMap = {
  calculator: React.createElement(CalculatorOutlined, {}),
  bulb: React.createElement(BulbOutlined, {}),
  team: React.createElement(TeamOutlined, {}),
  laptop: React.createElement(LaptopOutlined, {}),
  sound: React.createElement(SoundOutlined, {}),
  user: React.createElement(UserOutlined, {}),
  ellipsis: React.createElement(EllipsisOutlined, {}),
  setting: React.createElement(SettingOutlined, {}),
  'line-chart': React.createElement(LineChartOutlined, {})
}

export type ProfileUpdateType = {
  profile_skills_attributes: { skill_name?: string; experience?: number }[]
  location_id?: number
  job_categories: number[]
  salary_currency?: string
  expected_salary_cents?: number
  level?: number
  job_title?: string
  skip_onboarding?: boolean
}
export type OnboadingType = {
  job_categories?: NestedCategoryType[]
  skills?: SkillType[]
  locations?: FreecLocationType[]
  languages?: LanguageType[]
  profile?: ProfileUpdateType
}
export const getDefaultOnboarding = (): OnboadingType => ({
  profile: { profile_skills_attributes: [], job_categories: [] }
})
export const OnboardingReducer = (state: OnboadingType = getDefaultOnboarding(), action: Action): OnboadingType => {
  switch (action.type) {
    case ONBOARDING_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
export function useOnboadingData(): {
  profile: ProfileUpdateType
  changeProfile: (profile: ProfileUpdateType) => void
  skills?: SkillType[]
  locations?: FreecLocationType[]
  job_categories?: NestedCategoryType[]
  job_levels?: JobLevelType[]
  skill_years?: SkillYearType[]
  submitOnboarding: () => Promise<AxiosResponse>
  skipOnboarding: () => Promise<AxiosResponse>
  category_id_icom_map: CategoryIdIconMap
} {
  const { state, dispatch } = useData()
  const onboarding = state.onboarding
  const { client } = useClient()
  const changeProfile = (profile: ProfileUpdateType) => {
    dispatch({ type: ONBOARDING_STATE_CHANGE, payload: { profile } })
  }
  useEffect(() => {
    if (!onboarding.job_categories) {
      client.get(API_URL_JOB_CATEGORIES, {}).then((res: AxiosResponse<JobCategorySerializeType>) => {
        dispatch({
          type: ONBOARDING_STATE_CHANGE,
          payload: {
            job_categories: res.data.data.map((ser) => ({
              ...ser.attributes,
              subs: ser.attributes.subs.data.map((ser2) => ser2.attributes)
            }))
          }
        })
      })
    }
    if (!onboarding.skills) {
      client.get(API_URL_SKILLS, {}).then((res: AxiosResponse<FastJsonList<SkillType>>) => {
        dispatch({ type: ONBOARDING_STATE_CHANGE, payload: { skills: res.data.data.map((ser) => ser.attributes) } })
      })
    }
    if (!onboarding.locations) {
      client.get(API_FETCH_LOCATION, {}).then((res: AxiosResponse<FastJsonList<FreecLocationType>>) => {
        dispatch({ type: ONBOARDING_STATE_CHANGE, payload: { locations: res.data.data.map((ser) => ser.attributes) } })
      })
    }
  }, [])

  const submitOnboarding = () => client.post(`/api/v2/candidates/profile`, { data: { ...onboarding?.profile, on_boarding: true } })
  const skipOnboarding = () => client.post(`/api/v2/candidates/profile`, { data: { ...onboarding?.profile, skip_onboarding: true } })
  return {
    skills: onboarding.skills,
    locations: onboarding.locations,
    job_categories: onboarding.job_categories,
    job_levels: jobLevel,
    skill_years: skill_years,
    profile: state.onboarding.profile,
    changeProfile,
    submitOnboarding,
    skipOnboarding,
    category_id_icom_map
  }
}
