import { useEffect } from 'react'
import { getIsServer, parsePramsToQueryString, removeEmptyProp } from '../utilities/common'
import { useHistoryData } from '../store/HistoryDta'
import queryString from 'query-string'
import qs from 'qs'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
export type TraslateFuncType = (message: string, parrams?: { [key: string]: string | number } | string, options?: any) => string

const modalArray: { [id: string]: boolean } = {}

export function useModalRouterPush() {
  const router = useAppRouter()
  return {
    pushModal: (pushParams) => {
      const oldQuery = queryString.parse(window.location.search)
      Object.keys(pushParams).forEach((propKey) => {
        modalArray[propKey] = true
      })
      const searchString = parsePramsToQueryString({ ...oldQuery, ...pushParams })
      const pushPathName = location.pathname + (searchString ? `/?${searchString}` : '')
      router.replace(pushPathName, undefined, { shallow: true, scroll: false })
    }
  }
}
export function useRouterQueryPush() {
  const router = useAppRouter()
  const oldQuery = getIsServer() ? router.query : queryString.parse(window.location.search)

  return {
    query: oldQuery,
    pushQuery: (paritalQuery) => {
      Object.keys(paritalQuery).forEach((propKey) => {
        modalArray[propKey] = true
      })
      const searchString = parsePramsToQueryString({ ...oldQuery, ...paritalQuery })
      const pushPathName = location.pathname + (searchString ? `/?${searchString}` : '')
      router.push(pushPathName, undefined, { shallow: true, scroll: false })
    }
  }
}

export function useCollectHistory(): void {
  const { pushPath } = useHistoryData()
  const router = useAppRouter()
  useEffect(() => {
    const oldQuery = queryString.parse(window.location.search)
    const matchModal = Object.keys(oldQuery).find((param) => modalArray[param])
    if (!matchModal) {
      pushPath(router.asPath)
    }
  }, [router])
}
export function useUrlPushSearch<T>(): [T, (valutes: T) => void] {
  const router = useAppRouter()
  const currentState: T = qs.parse(getIsServer() ? router.asPath.split(/\?/)[1] : window.location.search.replaceAll('?', ''), {
    arrayFormat: 'bracket'
  })
  return [
    currentState,
    (newState: T) => {
      const merge = { ...currentState, ...newState } as unknown
      const searchString = qs.stringify(removeEmptyProp(merge as object))
      const pushPathName = location.pathname + (searchString ? `/?${searchString}` : '')
      router.push(pushPathName, undefined, { shallow: true, scroll: false })
    }
  ]
}
