import debounce from 'lodash.debounce'
import {
  API_URL_PUBLIC_APPLY_JOB_BY_SOCIAL,
  API_URL_PUBLIC_APPLY_JOB,
  API_URL_COMPANY_MEMBER,
  API_URL_CONSULTANT_CLIENT,
  API_FETCH_LOCATION,
  API_URL_CREATE_JOB_ALERT,
  API_URL_EDIT_JOB_ALERT
} from '../utilities/APIConstant'
import { FastJsonList, FreecLocationType } from '../type/Common'
import { ClientBaseType } from '../type/ClientType'
import client from '../utilities/Client'
import { AxiosResponse } from 'axios'
import { AuthenticateCridetialtype, MemberIndexType } from '../type/UserType'
import { PublicJobApplyType } from '../type/JobType'

export const apiGetClient: (key: string) => Promise<FastJsonList<ClientBaseType>> = debounce(
  (key: string) =>
    client.get(API_URL_CONSULTANT_CLIENT, { params: { name: key } }).then((rs: AxiosResponse<FastJsonList<ClientBaseType>>) => rs.data),
  400,
  {
    leading: true,
    trailing: false
  }
)

export const apiPublicGetTeamMember: (key?: string) => Promise<FastJsonList<MemberIndexType>> = debounce(
  (key: string) =>
    client.get(API_URL_COMPANY_MEMBER, { params: { key } }).then((res: AxiosResponse<FastJsonList<MemberIndexType>>) => {
      return res.data
    }),
  400,
  {
    leading: true,
    trailing: false
  }
)

export const apiPublicApplyJob: (jobId: number, data: PublicJobApplyType) => Promise<AuthenticateCridetialtype> = (jobId, data) => {
  const form = new FormData()
  form.append('email', data.email)
  form.append('password', data.password)
  form.append('from_product', data.from_product.toString())
  form.append('language', data.language.toString())
  if (data.full_name) {
    form.append('full_name', data.full_name)
  }

  if (data.introduction) {
    form.append('introduction', data.introduction)
  }
  if (data.phone_number) {
    form.append('phone_number', data.phone_number)
  }
  form.append('file', data.file)

  return client.post(API_URL_PUBLIC_APPLY_JOB(jobId), { data: form }).then((res: AxiosResponse<AuthenticateCridetialtype>) => {
    return res.data
  })
}

export const apiPublicApplySocial: (
  jobId: number,
  formData: { token: string; provider: number; from_product: number; language: number }
) => Promise<AuthenticateCridetialtype> = (jobId, formData) => {
  return client
    .post(API_URL_PUBLIC_APPLY_JOB_BY_SOCIAL(jobId), { data: formData })
    .then((res: AxiosResponse<AuthenticateCridetialtype>) => {
      return res.data
    })
}

export const apiPublicFetchLocations = (): Promise<FastJsonList<FreecLocationType>> =>
  client.get(API_FETCH_LOCATION, {}).then((res: AxiosResponse<FastJsonList<FreecLocationType>>) => res.data)

export const apiCreateJobAlert: (
  data: { email: string; keyword: string; location_id: string },
  isLogined: boolean,
  id: number | string
) => Promise<any> = (data, isLogined, id) => {
  const form = new FormData()
  if (!isLogined) {
    form.append('email', data.email)
  }
  form.append('keyword', data.keyword)
  form.append('location_id', data.location_id)

  if (id) {
    return client.put(API_URL_EDIT_JOB_ALERT(id), { data: form }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  return client.post(API_URL_CREATE_JOB_ALERT(isLogined), { data: form }).then((res: AxiosResponse<any>) => {
    return res.data
  })
}
