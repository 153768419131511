// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
// https://nextjs.org/docs/api-reference/next/router#routerevents
// [routeChangeStart, routeChangeComplete, routeChangeError, beforeHistoryChange, hashChangeStart, hashChangeComplete]
//
// routeChangeStart(url, { shallow }) - Fires when a route starts to change
// routeChangeComplete(url, { shallow }) - Fires when a route changed completely
// routeChangeError(err, url, { shallow }) - Fires when there's an error when changing routes, or a route load is cancelled
//                                            err.cancelled - Indicates if the navigation was cancelled
// beforeHistoryChange(url, { shallow }) - Fires just before changing the browser's history
// hashChangeStart(url, { shallow }) - Fires when the hash will change but not the page
// hashChangeComplete(url, { shallow }) - Fires when the hash has changed but not the page

export const useNProgress = () => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = () => {
      // console.log(`App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`)
      NProgress.start()
    }

    const handleRouteChangeCompleteOrError = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeCompleteOrError)
    router.events.on('routeChangeError', handleRouteChangeCompleteOrError)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeCompleteOrError)
      router.events.off('routeChangeError', handleRouteChangeCompleteOrError)
    }
  }, [])
}
