import { Action } from '../type/Common'
const LP_STATE_CHANGE = 'LP_STATE_CHANGE'

interface ListParners {
  id: number
  path: string
  link: string
}
interface ListMentioned {
  id: number
  path: string
  link: string
  width: number
  height: number
}
interface listClients {
  id: number
  path: string
  content: string
  name: string
  position: string
}

interface listQuestions {
  id: string
  title: any
  content: any
}
export type LPType = {}

export const LPReducer = (state = {}, action: Action): LPType => {
  switch (action.type) {
    case LP_STATE_CHANGE: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
