import mixpanel from 'mixpanel-browser'
import { useCallback, useEffect, useMemo } from 'react'
import { deleteCookie, getCookie, setCookie } from '../@core/utilities/Cookies'
import { readToken } from '../@core/utilities/JWTToken'
import { createSingleTimeInvoke } from '../@core/utilities/SingleInvokeFuction'
import { getIsServer } from '../@core/utilities/SSR'
import { TokenPayload } from '../@core/type/TokenPayload'

const DATA_MIXPANEL_ATTR = 'data-mixpanel-event'
const IS_IDENTIFY_KEY_STORAGE = 'is-identified-mixpanel'
const EXPIRES_DAYS = 1
// Mixpanel is util file, so we can not access user from the store
// => we have to cache user when set userinfo to mixpanel
let user: UserInfo | null = null

export const USER_TYPE = {
  RECRUITER: 'Recruiter',
  CANDIDATE: 'Candidate',
  CONSULTANT: 'Consultant'
}

export const USER_TYPE_NAME_MAPPING = {
  0: USER_TYPE.RECRUITER,
  1: USER_TYPE.CANDIDATE,
  2: USER_TYPE.CONSULTANT
}

export enum LoginMethod {
  FORM = 'FORM',
  SOCIAL = 'SOCIAL'
}

export interface UserInfo {
  id: string
  name: string
  email: string
  userType: string // Candidate, Recruiter, Consultant
  companyId?: number
  companyName?: string
}

export interface CompanyInfo {
  id: string
  name: string
}

export const EVENTS = {
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  VERIFY_SIGNUP_EMAIL: 'Verify Signup Email',
  VERIFY_INVITE_EMAIL: 'Verify Invite Email',

  // Event for candidate
  VIEW_APPLICANT_FROM_CANDIDATE: 'View Applicant from Candidate',
  VIEW_APPLICANT_FROM_PIPELINE: 'View Applicant from Pipeline',
  CLICK_APPLY_BEFORE_LOGIN: 'Click Apply before Login',
  CLICK_APPLY_AFTER_LOGIN: 'Click Apply after Login',
  CLICK_APPLY_ON_APPLY_FORM: 'Click Apply on Apply form',
  CLICK_UPLOAD_CV: 'Click Upload CV',
  CLICK_APPLY_WITH_CV: 'Click Apply with CV',
  CLICK_APPlY_WITHOUT_CV: 'Click Apply without CV',
  APPLY_WITH_CV: 'Apply with CV',
  APPLY_WITHOUT_CV: 'Apply without CV',
  COMPLETE_APPLY_WITHOUT_CV_STEP_1: 'Complete Apply without CV step 1',
  COMPLETE_APPLY_WITHOUT_CV_STEP_2: 'Complete Apply without CV step 2',
  COMPLETE_APPLY_WITHOUT_CV_STEP_3: 'Complete Apply without CV step 3',
  CLICK_JOB_ALERT: 'Click Job Alert',
  CREATE_JOB_ALERT: 'Create Job Alert',
  CLICK_CREATE_CV_FOOTER_MENU: 'Click Create CV footer menu',
  CLICK_SALARY_TOOL_FOOTER_MENU: 'Click Salary Tool footer menu',

  // Event for company
  CLICK_JOBS_POST_MENU: 'Click Jobs Post Menu',
  CLICK_CREATE_JOB: 'Click Create Job',
  CLICK_PUBLISH: 'Click Publish',
  CLICK_VIEW_POLICY: 'Click View Policy',
  SEARCH_JOB_POST: 'Search Job Post',
  CLICK_CANDIDATE_MENU: 'Click candidate menu',

  REJECT_CANDIDATE: 'Reject Candidate',
  CHANGE_STATUS: 'Change Status',
  ADD_NOTE: 'Add Note',
  UPDATE_NOTE: 'Update Note',
  DELETE_NOTE: 'Delete Note',
  ADD_EVALUTION: 'Add Evalution',

  CLICK_HEADHUNT_SOLUTION: 'Click Headhunt Solution',
  CLICK_INVITE_MEMBERS_IN_JOB: 'Click Invite Members in Job Form',
  CLICK_INVITE_MEMBERS_BUTTON: 'Click Invite Members button',
  SEND_MEMBER_INVITATION: 'Send member invitation',
  CLICK_SHARE_JOB_JOB_CARD: 'Click share job icon on job card',
  CLICK_SHARE_JOB_FACEBOOK: 'Click share job to facebook',
  CLICK_SHARE_JOB_LINKEDIN: 'Click share job to linkedin',
  CLICK_COPY_JOB_LINK: 'Click copy job link',
  CLICK_MENU_ON_JOB_CARD: 'Click menu on job card',
  CLICK_VIEW_JOB_MENU_JOB_CARD: 'Click view job from menu job card',
  CLICK_SHARE_ICON_JOB_DETAIL: 'Click share job icon from job detail',
  CLICK_VIEW_JOB_JOB_DETAIL: 'Click view job from job detail',
  CLICK_EDIT_JOB_JOB_DETAIL: 'Click edit job from job detail',
  CLICK_PUBLISH_JOB_JOB_EDIT: 'Click publish job in job edit',
  CLICK_CONTACT_US: 'Click contact us',
  CLICK_BOOK_A_CONSULTANT: 'Click book a consultant',
  SWITCH_SHOW_NEW_CANDIDATE: 'Switch show new candidate'
}

const isEnable = (): boolean => {
  // Remove Mixpanel Tracking if users are the consultant
  return !!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && user?.userType !== USER_TYPE.CONSULTANT
}

/**
 * Auto track clicking event by add a listener to document
 * If element has attr data-mixpanel-event
 * -> add tracking event
 */
const trackingClickEvent = (e: MouseEvent) => {
  const targetEl = e.target as HTMLElement
  const eventName = targetEl.getAttribute(DATA_MIXPANEL_ATTR)

  if (eventName) {
    track(eventName)
  } else {
    // Check parent
    const parentTracking = targetEl.closest(`[${DATA_MIXPANEL_ATTR}]`)
    if (parentTracking) {
      track(parentTracking.getAttribute(DATA_MIXPANEL_ATTR))
    }
  }

  // Some case which is using stopPropagation -> this function can not be triggered
  // -> need to call tracking manual
}

const getUserInfoFromToken = (token: string): UserInfo => {
  const userFromToken: TokenPayload = readToken(token)
  //@ts-ignore
  const userInfo: UserInfo = {
    // @todo: fix mixpanel
    // id: userFromToken.user_id,
    // name: userFromToken.full_name,
    // email: userFromToken.email,
    // companyId: userFromToken.current_company_id,
    // companyName: userFromToken.current_company_name,
    userType: USER_TYPE_NAME_MAPPING[userFromToken.user_type]
  }
  return userInfo
}

export const init = (): void => {
  if (isEnable()) {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
    !getIsServer() && document.addEventListener('click', trackingClickEvent)
  }
}

export const track = (eventName: string, data?: Object): void => {
  if (!isEnable()) return
  mixpanel.track(eventName, data)
}

export const identify = (userId: string): void => {
  if (!isEnable()) return
  mixpanel.identify(userId)
  setCookie(IS_IDENTIFY_KEY_STORAGE, 'true', EXPIRES_DAYS)
}

export const reset = (): void => {
  if (!isEnable()) return
  mixpanel.reset()
  deleteCookie(IS_IDENTIFY_KEY_STORAGE)
}

export const alias = (employeeId: string, existingId?: string): void => {
  if (!isEnable) return
  mixpanel.alias(employeeId, existingId)
}

export const distinctId = (): string => {
  if (!isEnable()) return
  return mixpanel.get_distinct_id()
}

export const registerUserFromToken = (token: string): void => {
  if (!isEnable()) return
  const user = getUserInfoFromToken(token)

  alias(user.id)
  registerUser(user)
}

export const registerUser = (userInfo: UserInfo): void => {
  if (!isEnable()) return
  const { name, email } = userInfo
  const data = {
    $name: name,
    $email: email
  }

  mixpanel.register(data)
  updateUserInfo(userInfo)
  mixpanel.people.set_once({
    'Registration Date': new Date().toISOString()
  })
}

export const registerCompany = (companyInfo: CompanyInfo): void => {
  if (!isEnable()) return
  const { id, name } = companyInfo

  const data = {
    'Company ID': id,
    'Company Name': name
  }

  mixpanel.register(data)
  mixpanel.people.set(data)
  mixpanel.people.set_once({
    'Registration Date': new Date().toISOString()
  })
}

export const trackLogin = (loginMethod: LoginMethod): void => {
  if (!isEnable()) return
  mixpanel.people.set({
    'Last Login': new Date().toISOString()
  })
  track('Login', { 'Login Method': loginMethod })
}

export const identifyByToken = (token: string): void => {
  if (!isEnable()) return
  const user = getUserInfoFromToken(token)

  identify(user.id)
  updateUserInfo(user)
}

export const updateUserInfo = (_user: UserInfo): void => {
  user = _user

  if (!isEnable()) return
  const data = {
    $name: user.name,
    $email: user.email,
    'User Type': user.userType,
    'Company Id': user.companyId,
    'Company Name': user.companyName
  }

  mixpanel.people.set(data)
}

export const isIndetified = (): boolean => {
  return getCookie(IS_IDENTIFY_KEY_STORAGE) === 'true'
}

const singleInnitInvoke = createSingleTimeInvoke(init)
export const useMixpanel = () => {
  useEffect(() => {
    singleInnitInvoke()
  }, [])
  return {
    LoginMethod: LoginMethod,
    alias: useCallback(alias, []),
    track: useCallback(track, []),
    registerUserFromToken: useCallback(registerUserFromToken, []),
    EVENTS: useMemo(() => EVENTS, []),
    identifyByToken: useCallback(identifyByToken, []),
    trackLogin: useCallback(trackLogin, [])
  }
}
