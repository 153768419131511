export const API_URL_RESEND_EMAIL = '/api/v2/companies/auths/register/resend'
export const API_URL_VERIFY_REGISTER = 'api/v2/companies/auths/register/verify'
export const API_URL_LOGIN = '/api/v2/companies/auths/login'
export const API_URL_REGISTER = '/api/v2/companies/auths/register'
export const API_URL_CANDIDATE_REGISTER = '/api/v2/companies/auths/register/candidate'
export const API_URL_FETCH_ME = '/api/v2/auths/me'
export const API_URL_RESET_PASSWORD = '/api/v2/companies/auths/forgot-password'
export const API_URL_ME = '/api/v2/auths/me'
export const API_URL_SAVE_JOB = '/api/v2/auths/me'

export const API_URL_FORGOT_PW_SEND_EMAIL = '/api/v2/companies/auths/forgot-password/send'
export const API_URL_FORGOT_PW_VERIFY_TOKEN = '/api/v2/companies/auths/forgot-password/verify'

export const API_URL_SUBMIT_PROFILE = '/api/v2/companies/companies'
export const API_URL_SUBMIT_ONBOARDING = '/api/v2/companies/companies'
export const API_URL_INDUSTRIES = '/api/v2/public/industries'
export const API_URL_UPDATE_PASSWORD = '/api/v2/companies/accounts/reset-password'
export const API_URL_ACCOUNT = '/api/v2/account'

export const API_URL_UPLOAD_PHOTO = '/api/v2/medias/file'
export const API_URL_UPLOAD_IMAGE = '/api/v2/medias/image'
export const API_URL_SKILLS = '/api/v2/public/skills'
export const API_URL_JOB_CATEGORIES = '/api/v2/public/job_categories'
export const API_URL_CREATE_JOB = '/api/v2/companies/jobs'
export const API_URL_LIST_JOB = '/api/v2/companies/jobs'
export const API_URL_GET_JOB_DETAIL = '/api/v2/companies/jobs'
export const API_URL_GET_PROFILE_COMPANY = '/api/v2/companies/current_company'

export const API_URL_LIST_TEAM_MEMBER = '/api/v2/companies/members'
export const API_URL_INVITE_TEAM_MEMBER = '/api/v2/companies/members/invites'
export const API_URL_RESEND_INVITE_MEMBER_EMAIL = '/api/v2/companies/members/invites/resend'
export const API_URL_VERIFY_MEMBER_INVITE = '/api/v2/companies/members/invites/verify'
export const API_URL_UPDATE_MEMBER_LOGIN = '/api/v2/companies/members/invites/update'
export const API_URL_CANDIDATES_JOB = '/api/v2/candidates/jobs'
export const API_URL_CANDIDATES_MYJOB_SAVE = '/api/v2/candidates/jobs/save'
export const API_URL_CANDIDATES_MYJOB_APPLY = '/api/v2/candidates/jobs/apply'
export const API_URL_PUBLISHCOMPY = '/api/v2/public/companies/123'
export const API_URL_EMPLOYER_REGISTER_SOCIAL = '/api/v2/companies/auths/register/social'
export const API_URL_CANDIDATES_LOGIN_SOCIAL = '/api/v2/companies/auths/login/social'
export const API_URL_CANDIDATES_CVS = '/api/v2/candidates/cvs'
export const API_URL_CANDIDATES_APPLY_JOB = (jobId: number): string => `/api/v2/candidates/jobs/${jobId}/apply`

export const API_URL_FETCH_ME_CANDIDATE = '/api/v2/auths/me'
export const API_FETCH_LOCATION = '/api/v2/public/locations'
export const API_URL_PROFILE = '/api/v2/public/profile'
export const API_CANDICATE_PROFILE = '/api/v2/candidates/profile'
export const API_URL_APPLICANTS = '/api/v2/companies/applicants'

export const API_URL_COMPANY_JOB_STAGE_SEARCH_LIST = '/api/v2/companies/jobs/stages'

export const API_URL_LOCATION = '/api/v2/public/locations'
export const API_URL_COMPANIES_MEMBERS = '/api/v2/companies/members?fetch_id=true'

export const API_URL_PUBLIC_APPLY_JOB = function (jobId: number): string {
  return `/api/v2/public/jobs/${jobId}/apply`
}

export const API_URL_PUBLIC_APPLY_JOB_BY_SOCIAL = function (jobId: number): string {
  return `/api/v2/public/jobs/${jobId}/social/apply`
}

export const API_URL_PUBLIC_APPLY_JOB_WITH_SOCIAL_WITHOUT_CV = `/api/v2/auths/social_quick_apply`

export const API_URL_COMPANY_MEMBER = `/api/v2/companies/members?active=true&all=1`

export const API_URL_JOBS_CANDIDATES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/candidates`
}
export const API_URL_CONTACT_REQUEST = '/api/v2/public/contact_request'
export const API_URL_CONTACT_REQUEST_LOGIN = '/api/v2/companies/contact_requests'

export const API_URL_COMPANY_JOB_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/stages`
}
export const API_URL_COMPANY_APPLICANTS_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${jobId}/stages/update`
}

export const API_URL_COMPANY_CANDIDATE_EVALUATION = (applicantId: string): string => `/api/v2/companies/profiles/${applicantId}/evaluations`
export const API_URL_COMPANY_CANDIDATE_EVALUATION_GET = (applicantId: string | number, id: string): string =>
  `/api/v2/companies/profiles/${applicantId}/evaluations/${id}`
export const API_URL_JOB_NOTES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes`
}
export const API_URL_UPDATE_JOB_NOTES = (jobId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes/${id}`
}

export const API_URL_APPLICANTS_NOTES = (profile_id: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/notes`
}
export const API_URL_UPDATE_APPLICANTS_NOTES = (profile_id: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/notes/${id}`
}
export const API_URL_COMPANY_JOBS = `/api/v2/companies/jobs?fetch_id=true&status_arr[]=1&status_arr[]=2&freec_status_arr[]=1&freec_status_arr[]=3`
export const API_SHARE_PROFILE = (token: string | string[]): string => {
  return `/api/v2/public/applicants/${token}/profile`
}
export const API_URL_TALENT_POOL = `/api/v2/public/talent_pools`

export const API_URL_PROFILE_APPLIED_JOB = (profile_id: number | string | string[], { ignore }): string => {
  return `/api/v2/companies/profiles/${profile_id}/applied_jobs${ignore === true ? '?ignore_offer=true' : ''}`
}
export const API_URL_POST_INTERVIEWS_APPLICANTS = (profile_id: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/interviews`
}
export const API_URL_SHOW_AN_INTERVIEW = (profile_id: number | string | string[], interviewId: number | string | string[]): string => {
  return `/api/v2/companies/profiles/${profile_id}/interviews/${interviewId}`
}
export const API_URL_GET_LIST_INTERVIEW = `/api/v2/companies/interviews`
export const API_URL_CANCEL_INTERVIEW = (profile_id: number | string, interview_id: number | string): string => {
  return `/api/v2/companies/profiles/${profile_id}/interviews/${interview_id}/cancel`
}

export const API_URL_INTERVIEW_APPLICANT = (profile_id: number | string): string => {
  return `/api/v2/companies/profiles/${profile_id}/interviews`
}
export const API_URL_ACTIVITY_LIST = (id: string | number) => `/api/v2/companies/profiles/${id}/activities`
export const API_URL_ACTIVITY_ACTION_TYPE_LIST = (id: string | number) => `/api/v2/companies/profiles/${id}/activities/action_types`
export const API_URL_REMOVE_MEMBER = (id_member: string | string[] | number) => `/api/v2/companies/members/${id_member}/active`

export const API_URL_JOBS_PROFILE = (profile_id: number | string, isEmployer?: boolean): string => {
  return `/api/v2/companies/profiles/${profile_id}/applicants${isEmployer === false ? '?job_order=true' : ''}`
}

export const API_URL_TALENT_POOLS = (profile_id: number | string): string => {
  return `/api/v2/companies/profiles/${profile_id}/profile_talent_pools`
}

export const API_URL_TALENT_POOLS_DELETE = (profile_id: number | string, id: number): string => {
  return `/api/v2/companies/profiles/${profile_id}/profile_talent_pools/${id}`
}

export const API_URL_TALENT_POOLS_LIST = (profile_id?: number | string): string => {
  if (!profile_id) {
    return '/api/v2/companies/talent_pools'
  }

  return `/api/v2/companies/talent_pools?profile_id=${profile_id}`
}

export const API_URL_COMPANY_LIST = '/api/v2/public/published_company'

export const API_URL_CONSULTANT_CREATE_OFFER = (profile_id: number | string | string[]) => `/api/v2/companies/profiles/${profile_id}/offers`
export const API_URL_CONSULTANT_APPLICANT_OFFER = (profile_id: number | string | string[], id: number | string | string[]) =>
  `/api/v2/companies/profiles/${profile_id}/offers/${id}`
export const API_URL_EMAILTEMPLATE_DETAIL = (id: string | string[] | number) => `/api/v2/companies/email_templates/${id}`

export const API_URL_OFFER_UPDATE_STATUS = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/profiles/${profile_id}/offers/${id}/update_status`
export const API_URL_PLACEMENT_CREATE = (profile_id: string | string[] | number) => `/api/v2/companies/profiles/${profile_id}/placements`
export const API_URL_PLACEMENT_UPDATE_STATUS = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/profiles/${profile_id}/placements/${id}/update_status`
export const API_URL_PLACEMENT_LIST = `/api/v2/companies/placements`
export const API_URL_PLACEMENTS = (profile_id: string | string[] | number) => `/api/v2/companies/profiles/${profile_id}/placements`
export const API_URL_PLACEMENT_DETAIL = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/profiles/${profile_id}/placements/${id}`
export const API_URL_PLACEMENT_UPDATE = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/profiles/${profile_id}/placements/${id}`
export const API_URL_CONSULTANT_OFFERS_LIST = `/api/v2/companies/offers`

export const API_URL_PREMIUM_COMPANY = (company_id: string | number | string[]) => `/api/v2/public/branded_company/${company_id}`

export const API_ADMIN_COMPANY_LIST = '/api/v2/admins/companies'
export const API_URL_ADMIN_GET_JOBS = `/api/v2/admins/jobs`
export const API_URL_ADMIN_CREATE_JOB = `/api/v2/admins/jobs`

export const API_URL_PROFILE_WITHOUT_CV = '/api/v2/candidates/profile/quick_apply_profile'

export const API_URL_CANDIDATES_JOB_ALERT = '/api/v2/candidates/job_alerts'
export const API_URL_CREATE_JOB_ALERT = function (isLogined: boolean): string {
  return isLogined ? '/api/v2/candidates/job_alerts' : '/api/v2/public/job_alerts'
}

export const API_URL_EDIT_JOB_ALERT = (id) => `/api/v2/candidates/job_alerts/${id}`

export const API_URL_RECRUITER_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/companies/applicants/${candidateId}/disqualify`

export const API_COMPANIES_RECRUITERS = `/api/v2/companies/recruiters`
export const API_COMPANIES_RECRUITERS_ASSIGN_JOB = (jobID: number | string): string =>
  `/api/v2/companies/jobs/${jobID}/recruiters/assign_job`
export const API_COMPANIES_RECRUITERS_REMOVE_ASSIGN_JOB = (jobID: number | string, id: number | string): string =>
  `/api/v2/companies/jobs/${jobID}/recruiters/${id}/remove_assigned_job`

export const API_COMPANIES_PROFILE = `/api/v2/companies/profiles`
export const API_CONSULTANT_TASK = '/api/v2/companies/tasks'
export const API_CONSULTANT_TASK_UPDATE = (id: string | string[] | number) => `/api/v2/companies/tasks/${id}`
export const API_CONSULTANT_TASK_UPDATE_STATUS = (id: string | string[] | number) => `/api/v2/companies/tasks/${id}/update_data`
export const API_CONSULTANT_PROFILE_TASK = (id: string | string[] | number) => `/api/v2/companies/profiles/${id}/tasks`
export const API_CONSULTANT_DELETE_TASK = (id: string | string[] | number) => `/api/v2/companies/tasks/${id}`
export const API_ADMIN_MEMBERS = `/api/v2/admins/members`
export const API_ADMIN_INVITES = `/api/v2/admins/members/invites`
export const API_ADMIN_INVITES_RESEND = `${API_ADMIN_INVITES}/resend`
export const API_ADMIN_INVITES_VERIFY = `${API_ADMIN_INVITES}/verify`
export const API_ADMIN_INVITES_UPDATE = `${API_ADMIN_INVITES}/update`
export const API_ADMIN_ACTIVE_MEMBERS_ACTIVE = (id: string | string[] | number) => `/api/v2/admins/members/${id}/active`

export const API_CONSULTANT_BULK_ACTION_ASSIGN_JOB = (job_id: string | string[] | number) =>
  `/api/v2/consultants/jobs/${job_id}/bulk_candidates`

export const API_URL_CLIENT_INFO_FROM_TOKEN = (token: string): string => `/api/v2/consultants/clients/info?token=${token}`
export const API_URL_CLIENT_ACTIVE = `/api/v2/consultants/clients/active`

export const API_ROLE_INVITE_MEMBER = `/api/v2/companies/roles`
export const API_UPDATE_ROLE_MEMBER = (id: string | string[] | number) => `/api/v2/companies/members/${id}/change_role`

export const API_COMPANY_BRANCH_ADDRESSES = 'api/v2/companies/company_branch_addresses'
export const API_FETCH_PROFILE_DETAIL_SEARCH_CV = (id: string | string[] | number) => `/api/v2/companies/profiles/${id}/meili_profile`
export const API_APPLICANT_MATCHING = (
  applicant_id: string | string[] | number
) => `/api/v2/companies/applicants/${applicant_id}/applicant_mathching

`
